import React, { FC, useEffect } from 'react'
import { createStore, applyMiddleware, compose } from 'redux'
import { Router, Route, Redirect } from 'react-router-dom'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { createBrowserHistory } from 'history'
import createSagaMiddleware from 'redux-saga'
import rootReducer from '@root-auth/store/redusers/rootreducer'
import rootSaga from '@root-auth/store/sagas/root'
import { ThemeProvider } from '@material-ui/core'
import Login from '@root-auth/components/Auth/Login'
import { AutoLogin } from '@root-auth/components/Auth/AutoLogin'
import Registration from '@root-auth/components/Auth/Registration'
import Recall from '@root-auth/components/Auth/Recall'
import Restore from '@root-auth/components/Auth/Restore'
import Verify from '@root-auth/components/Auth/Verify'
import { theme } from './styles/mui-theme'
import '@root-auth/styles/main.scss'
import Ymetrika from './components/Ymetrika'
import { Notify } from '@root-auth/components/Notify/Notify'
import Faq from '@root-auth/components/Faq';
import { IState } from '@root-gipro/store/interfaces/state'
declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
	}
}

export const history = createBrowserHistory()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const sagaMiddleware = createSagaMiddleware()
export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)))

// export const modeURL = process.env.NODE_ENV === 'development' ? `//localhost:3000/` : `https://dev.gi-pro.ru`
export const modeURL =
	process.env.NODE_ENV === 'development' ? `//localhost:3000/` : `https://${process.env.REACT_APP_ENV}`

sagaMiddleware.run(rootSaga)

const App: FC = () => {

	// const { isFilterOpen } = useSelector((state: IState) => state.maket)
	// const { accessToken } = useSelector((state: IState) => state.auth)
	// const dispatch = useDispatch()

	const accessToken = localStorage.getItem('access_token')
	const urlParams = new URLSearchParams(window.location.search);
	const requestUrl = urlParams.get('requestUrl')
	// console.log('***')
	// console.log(requestUrl)
	localStorage.setItem('requestUrl',requestUrl || '')

	if (accessToken) {

		window.location.href = modeURL
	}

	// useEffect(() => {
	// 	if (accessToken) {
	// 		dispatch(fetchPublicInfo())
	// 	} else {
	// 		dispatch(authorize())
	// 	}
	// }, [accessToken])


	return (
		<ThemeProvider theme={theme}>
			<Provider store={store}>
				<Router history={history}>
					<Route
						exact
						path='/'
						render={() => {
							if (store.getState().auth.login) {
								return (window.location.href = modeURL)
							} else {
								return <Redirect to='/login' />
							}
						}}
					/>
					<Route path='/login' component={Login} />
					<Route path='/autologin' component={AutoLogin} />
					<Route path='/registration' component={Registration} />
					<Route path='/recall' component={Recall} />
					<Route path='/restore/:code' component={Restore} />
					<Route path='/verify/:code' component={Verify} />
					<Notify />
					<Ymetrika />
					<Faq/>
				</Router>
			</Provider>
		</ThemeProvider>
	)
}

export default App
