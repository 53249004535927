import { call, put } from 'redux-saga/effects'
import { fetchData } from '@root-auth/utils/common'
import { showNotify } from '@root-auth/store/actions/notify/notify'

const errorHandler = () => { alert(0) }

export default function* passRecall({ login }) {
	const res = yield call(
		fetchData,
		'/passwords/recall',
		res => res,
		null,
		{
			'X-Requested-With': 'XMLHttpRequest',
			'Content-Type': 'application/json',
		},
		'POST',
		JSON.stringify({ login })
	)

	if (res.status === 'success') {
		yield put(
			showNotify({
				type: 'success',
				message: 'Проверьте Вашу почту',
			})
		)
	}

	if (res.status === 'failure') {
		let message = 'Ошибка восстановления пароля'
		if (typeof res.errors.login !== 'undefined') {
			message = res.errors.login.notExists
		}
		if (typeof res.errors.email !== 'undefined') {
			message = res.errors.email.notVerified
		}
		yield put(
			showNotify({
				type: 'error',
				message: message,
			})
		)
	}
}

