import { createMuiTheme } from '@material-ui/core'

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#1BAAF0',
			dark: '#1690ce',
			light: '#31b2f1',
		},
		secondary: {
			main: '#3f4f5e',
			light: '#495c6d',
			dark: '#2b353f',
		},
	},
	overrides: {
		MuiTextField: {
			root: {
				marginBottom: 12,
			},
		},
		MuiInputBase: {
			root: {
				height: 36,
				fontSize: '0.8em',
			},
		},
		MuiOutlinedInput: {
			root: {
				borderRadius: '6px',
			},
			input: {
				padding: '10px 0 10px 10px',
			},
			notchedOutline: {
				borderColor: 'currentColor',
				borderWidth: 2,
			},
		},
		MuiInputLabel: {
			outlined: {
				transform: ' translate(12px, 12px) scale(1)',
			},
		},
		MuiFormLabel: {
			root: {
				fontSize: '0.8em',
			},
		},
		// MuiFormControlLabel: {
		// 	root: {
		// 		marginRight: 0,
		// 		marginLeft: 0,
		// 		justifyContent: 'space-between',
		// 		width: '100%',
		// 	},
		// },
		MuiTypography: {
			body1: {
				fontSize: 12,
				userSelect: 'none',
			},
		},
		MuiCheckbox: {
			root: {
				color: 'currentColor',
				marginLeft: -9,
			},
		},
		MuiIconButton: {
			label: {
				width: 20,
				height: 20,
			},
		},
	},
})

export { theme }
