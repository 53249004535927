/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { TextField, CircularProgress } from '@material-ui/core'
import { verifyNewUSer, resendVerify } from '@root-auth/store/actions/auth/newUser'
import MuiButton from '../MuiComponents/MuiButton'

interface IVerify {
	userSuccess: boolean
	verifyNewUSer: (token: string) => void
	resendVerify: (email: string) => void
}

const Verify: React.FC<IVerify> = ({ userSuccess, verifyNewUSer, resendVerify }) => {
	const [email, setEmail] = useState('')
	const getToken = window.location.toString().match(/[^\/]*$/)!

	useEffect(() => {
		if (getToken) verifyNewUSer(getToken[0])
	}, [])

	return (
		<div className='login'>
			<Link to='/'>
				<img className='login__image' src='/assets/images/SVG/logo.svg' alt='LOGO' />
			</Link>
			<form
				className='login__form'
				onSubmit={e => {
					e.preventDefault()
					resendVerify(email)
				}}
			>
				{userSuccess === undefined ? (
					<>
						<CircularProgress />
					</>
				) : userSuccess === false ? (
					<>
						<h2 className='login__title'>Ошибка верификации</h2>
						<div className='login__fields'>
							<TextField
								label='Email'
								variant='outlined'
								fullWidth
								type='email'
								name='email-verify'
								autoComplete='off'
								value={email}
								onChange={e => setEmail(e.currentTarget.value)}
								inputProps={{ inputMode: 'email' }}
								required
							/>
						</div>
						<div className='login__actions'>
							<MuiButton disabled={email ? false : true} fullWidth variant='contained' color='primary' type='submit'>
								Отправить новый запрос
							</MuiButton>
							<div className='login__actions-item'>
								Уже есть аккаунт?{' '}
								<Link to='/login' className='link-blue'>
									Войти
								</Link>
							</div>
						</div>
					</>
				) : (
					<>
						<h2 className='login__title'>Верификация прошла успешно!</h2>
						<div className='login__actions'>
							<Link to='/login' className='link link-white'>
								<MuiButton fullWidth variant='contained' color='primary'>
									Войти
								</MuiButton>
							</Link>
						</div>
					</>
				)}
			</form>
		</div>
	)
}

const mapStateToProps = (state: any) => ({
	userSuccess: state.auth.userSuccess,
})

const mapDispatchToProps = (dispatch: any) => ({
	verifyNewUSer: (token: string) => dispatch(verifyNewUSer(token)),
	resendVerify: (email: string) => dispatch(resendVerify(email)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Verify)
