/* eslint-disable no-useless-escape */
import { createBrowserHistory } from 'history'
import { store } from '@root-auth/App'
import { SET_AUTH } from '@root-auth/utils/types'
import { showNotify } from '@root-auth/store/actions/notify/notify'

export const forwardTo = location => {
	const history = createBrowserHistory()
	history.push(location)
}

export const getCookie = name => {
	let matches = document.cookie.match(
		new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
	)

	return matches ? decodeURIComponent(matches[1]) : ''
}

export const setCookie = (name, value, options = {}) => {
	options = {
		path: '/',
		...options,
	}

	if (options.expires instanceof Date) {
		options.expires = options.expires.toUTCString()
	}

	let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value)

	for (let optionKey in options) {
		updatedCookie += '; ' + optionKey
		let optionValue = options[optionKey]
		if (optionValue !== true) {
			updatedCookie += '=' + optionValue
		}
	}

	document.cookie = updatedCookie
}

export const deleteCookie = name => {
	setCookie(name, '', {
		'max-age': -1,
	})
}

// Дефолтные заголовки с авторизацией
export const fetchHeaders = () => {
	return {
		'X-Requested-With': 'XMLHttpRequest',
		'Content-Type': 'application/json',
	}
}

export const fetchData = (
	url,
	callback,
	errorHandler = null,
	headers = fetchHeaders(),
	method = 'GET',
	body = null
) => {
	// console.log(process.env.REACT_APP_TRANSFER_PROTOCOL)
	const modeDevCredentials =
		process.env.NODE_ENV === 'development' && url === '/auth/login' ? { credentials: 'include' } : null
	return fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1${url}`, {
		...modeDevCredentials,
		method,
		headers,
		body: body,
	})
		.then(res => {
			if (res.status === 401 || res.status === 403) {
				store.dispatch({ type: SET_AUTH, login: false })
				throw new Error('Error => auth')
			} else if (res.status === 500) {
				store.dispatch(
					showNotify({
						type: 'error',
						message: 'Ошибка: при обращении к серверу произошла ошибка',
					})
				)
				throw new Error('error')
			} else {
				return res.json()
			}
		})
		.then(res => {
			if (process.env.NODE_ENV === 'development') {
				if (res.status === 'failure') {
					store.dispatch(
						showNotify({
							type: 'error',
							message: res.message,
						})
					)
				}
				if (res.error) {
					store.dispatch(
						showNotify({
							type: 'error',
							message: res.error,
						})
					)
					throw new Error(res.error)
				}
			}
			return callback(res)
		})
		.catch(e => {
			if (errorHandler) errorHandler(e)
		})
}
