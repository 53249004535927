import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import Icon from '../Icon/';
// import Pdf from 'doc.pdf';

const FaqInfoStyle = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  transition: all 1s 0.2s;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  right: -20px;
  bottom: -20px;
`;
const FaqChatStyle = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  transition: all 1s 0.2s;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  right: -20px;
  bottom: -20px;
`;
const FaqYoutubeStyle = styled.div`
  position: absolute;
  background: transparent;
  transition: all 1s 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  right: -20px;
  bottom: -20px;
`;
const FaqLampStyle = styled.div`
  width: 60px;
  height: 60px;
  position: absolute;
  z-index: 10;
  background: #1baaf0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const FaqWrapperStyle = styled.div`
  position: fixed;
  display: flex;
  z-index: 11;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  bottom: 70px;
  right: 50px;
  img {
    border-radius: initial;
  }
  &:hover ${FaqInfoStyle} {
    right: 25px !important;
    bottom: 25px !important;
  }
  &:hover ${FaqChatStyle} {
    right: 50px !important;
    bottom: -20px !important;
  }
  &:hover ${FaqYoutubeStyle} {
    right: 25px !important;
    bottom: -65px !important;
  }
`;

const Faq = () => {
  const [showFaq, setShowFaq] = useState(true);

  return (
    <FaqWrapperStyle>
      <FaqLampStyle onClick={() => setShowFaq(!showFaq)}>
        <Icon name="lamp" />
      </FaqLampStyle>
      <FaqInfoStyle>
        <a href={require('./gipro_docs.pdf')} target="_blanc">
          <Icon name="pdf" />
        </a>
      </FaqInfoStyle>
      <FaqChatStyle>
        <a href="javascript:void(0)"
          onClick={() => {
            // @ts-ignore
            jivo_api.open();
            return false;
          }}
        >
          <Icon name="chat" />
        </a>
      </FaqChatStyle>
      <FaqYoutubeStyle>
        <a target="_blank" href="https://www.youtube.com/playlist?list=PLC_1-R7PaP58uiGcgP-JcZYZX65at-oGA">
          <Icon name="youtube" />
        </a>
      </FaqYoutubeStyle>
    </FaqWrapperStyle>
  );
};

export default Faq;
